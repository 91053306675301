import { PayloadAction } from 'store/types/Actions'
import { HeaderData } from 'store/types/HeaderData'

/*
 * action types
 */
export const SET_HEADER_DATA = 'HEADER_DATA::SET_HEADER_DATA'

export const actionTypes = {
  SET_HEADER_DATA,
}

interface RailsResult {
  mapboxAccessToken: string
  experiments?: {
    [name: string]: boolean,
  },
  locale: string
  isCurrentCountryDach: boolean
  headerData: {
    countryName: string
    logoUrl: string
  }
}

type SetHeaderDataAction = PayloadAction<typeof SET_HEADER_DATA, HeaderData>

/*
 * action creators
 */
export const setHeaderData = (railsResult: RailsResult): SetHeaderDataAction => {
  const headerData = {
    mapboxAccessToken: railsResult.mapboxAccessToken,
    experiments: railsResult.experiments || {},
    locale: railsResult.locale,
    isCurrentCountryDach: railsResult.isCurrentCountryDach,
    countryName: railsResult.headerData.countryName,
    logoUrl: railsResult.headerData.logoUrl,
  }

  return {
    type: SET_HEADER_DATA,
    payload: headerData,
  }
}

export const actionCreators = {
  setHeaderData,
}

/*
 * reducer
 */
const initialState: HeaderData = {}

export default function headerDataReducer (state = initialState, action: SetHeaderDataAction) {
  switch (action.type) {
    case SET_HEADER_DATA:
      return action.payload
    default:
      return state
  }
}
