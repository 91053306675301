import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useIntl } from 'react-intl'

import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'
import Menu from 'components/Menu'

import Search from 'components/Header/Navigation/Search'
import NavigationItemWithOverlay from 'components/Header/components/NavigationItem/WithOverlay'
import {
  EVENTINC_MAGAZINE_LINKS,
  EVENTINC_FAQ_LINKS,
  EVENTINC_PARTNER_LINKS,
} from 'components/Header/Navigation/links'

import { RootState } from 'store/reducer'
import { buildUrl } from 'helpers/params'
import classNames from 'helpers/css'
import { showEventincBusinessLink, getEventincBusinessLink } from 'helpers/localization'

import styles from './index.module.scss'

const MainMenu: React.FC<MainMenuProps> = (props) => {
  const {
    currentUser,
    countryName,
    isCurrentCountryDach,
  } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
    countryName: state.headerData.countryName,
    isCurrentCountryDach: state.headerData.isCurrentCountryDach,
  }), shallowEqual)

  const intl = useIntl()

  const { showUserEvents } = props

  const isUpgradeSubscriptionRequired = currentUser.permissions?.upgrade_subscription

  const showPartnerLink = !currentUser.isSignedIn && !currentUser.hasAccessToken && !showUserEvents

  const partnerLink = EVENTINC_PARTNER_LINKS[countryName as keyof typeof EVENTINC_PARTNER_LINKS]
  const showBusinessLink = showEventincBusinessLink(intl.locale)
  const isWhiteLabelDomain = currentUser.isWhiteLabelDomain

  return (
    <NavigationItemWithOverlay icon={<Icon name="menu" />}>
      <Menu mode="inline" className={classNames(styles, 'header__navigation__mobile-main-menu')}>
        <Search
          className={classNames(styles, 'header__navigation__mobile-main-menu__search')}
          isInputMode
        />

        {showBusinessLink && !isUpgradeSubscriptionRequired && !isWhiteLabelDomain && (
          <Menu.Item key="business">
            <a href={getEventincBusinessLink(intl.locale)} target="_blank">
              <FormattedMessage id="navigation.link.business" />
            </a>
          </Menu.Item>
        )}

        {showBusinessLink && isUpgradeSubscriptionRequired && (
          <Menu.Item
            key="business"
            className={classNames(styles, 'header__navigation__mobile-main-menu__upgrade-item')}
          >
            <a
              href={buildUrl(getEventincBusinessLink(intl.locale), { ref: 'header' })}
              target="_blank"
              className="link"
            >
              <FormattedMessage id="subscription.badge.upgrade" />
            </a>
          </Menu.Item>
        )}

        {showPartnerLink && partnerLink && (
          <Menu.Item key="become-a-partner">
            <a href={partnerLink} target="_blank">
              <FormattedMessage id="navigation.link.locationList" />
            </a>
          </Menu.Item>
        )}

        {isCurrentCountryDach && (
          <Menu.SubMenu
            title={<FormattedMessage id="newHeader.mobile.magazine" />}
            id="magazine"
          >
            {(
              Object.keys(EVENTINC_MAGAZINE_LINKS) as Array<keyof typeof EVENTINC_MAGAZINE_LINKS>
            ).map((key) => (
              <Menu.Item key={key}>
                <a href={EVENTINC_MAGAZINE_LINKS[key]}>
                  <FormattedMessage id={`newHeader.mobile.magazine.${key}`} />
                </a>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        )}

        {isCurrentCountryDach && (
          <Menu.SubMenu
            title={<FormattedMessage id="newHeader.mobile.faq" />}
            id="faq"
          >
            {(
              Object.keys(EVENTINC_FAQ_LINKS) as Array<keyof typeof EVENTINC_FAQ_LINKS>
            ).map(key => (
              <Menu.Item key={key}>
                <a href={EVENTINC_FAQ_LINKS[key]}>
                  <FormattedMessage id={`newHeader.mobile.faq.${key}`} />
                </a>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        )}
      </Menu>
    </NavigationItemWithOverlay>
  )
}

type MainMenuProps = {
  showUserEvents?: boolean | undefined
}

export default MainMenu
